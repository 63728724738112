import React from 'react';

import { AgentForm } from '../../components';

import salesagent from '../../assets/images/salesagent.jpg';

const SalesAgentProgramPage = () => {
  return (
    <div className="sales_agent_program_page__wrapper">
      <div className="agent_program__wrapper">
        <p className="sales_agent_program_page__headline">
          Sales Agent Program
        </p>
        <p className="sales_agent__text">
        Do you own, manage or sell in a retail environment?  Join us in transforming the way we communicate, 
        
        and earn lucrative channel partner commission streams.  Become a sales agent for 725-contact subscriptions!
          
           It's a perfect fit to complement the sales of new smartphones, phone repairs, mobile device accessories, 
          
          and smartphone insurance.
          </p>
          <br className="new_line_wide_page" />
          <p className="sales_agent__text">
          725-contact is actively recruiting retail sales agents across the U.S. It is a synergistic and easy "sell-with" solution, and takes only 20 minutes to learn to sell. If you manage, own, or sell in a retail environment, we can sign up your organization. We are seeking:
            <ul>
              <li>wireless phone retailers</li>
              <li>device repair retailers</li>
              <li>electronics retailers</li>
            </ul>
            The application takes only a minute. One of our reps will contact you within 48 hours with program details!
          </p>
        <div className="sales_agent_img__wrapper">
          <img src={salesagent} className="sales_agent_img" />
        </div>
      </div>

      <p className="apply__text">Apply to become a Sales Agent</p>
      <div className="form__wrapper">
        <AgentForm />
      </div>
    </div>
  );
};

export default SalesAgentProgramPage;
