import React, { useEffect, useState, useContext } from 'react';

import { MarketingContext } from '../../context';

import { AppStore, ResourcesDropDown } from '../../components';

const FAQ = () => {
  const { getFAQ } = useContext(MarketingContext);

  const [FAQContent, setFAQContent] = useState({
    Name: 'FAQ',
    About: '',
    QuestionsAndAnswers: [
      {
        Question: '',
        Answer: '',
      },
    ],
  });

  useEffect(() => {
    getFAQ().then((response) => setFAQContent(response));
  }, []);

  return (
    <div className="FAQPage__wrapper">
      <div className="FAQ__wrapper">
        <p className="FAQ__headline">{FAQContent.Name}</p>
        <p className="FAQ__about">{FAQContent.About}</p>
        {FAQContent.QuestionsAndAnswers.map((value) => (
          <ResourcesDropDown question={value.Question} answer={value.Answer} />
        ))}
      </div>
      <div className="appstore_wrapper">
        <AppStore />
      </div>
    </div>
  );
};

export default FAQ;
