import React, { useState, useEffect, useContext } from 'react';
import { AppStore } from '../../components';

import { MarketingContext } from '../../context';

import { GetStarted, Hero } from './components';
import CompanyNews from './components/CompanyNews/CompanyNews';

const HomePage = () => {
  const { getHomePage } = useContext(MarketingContext);

  const [getStartedTitle, setGetStartedTitle] = useState('');
  const [getStartedText, setGetStartedText] = useState('');
  const [companyNewsTitle, setCompanyNewsTitle] = useState('');
  const [companyNewsText, setCompanyNewsText] = useState('');

  useEffect(() => {
    getHomePage().then((response) => {
      console.log(response);

      setGetStartedTitle(response.GetStartedTitle);
      setGetStartedText(response.GetStartedText);
      setCompanyNewsTitle(response.CompanyNewsTitle);
      setCompanyNewsText(response.CompanyNewsText);
    });
  }, []);

  return (
    <div className="home__wrapper">
      <Hero />
      <div className="home__inner_wrapper">
        <GetStarted title={getStartedTitle} text={getStartedText} />
      </div>
      <div className="appstore_wrapper">
        <AppStore />
      </div>
      <div className="home__inner_wrapper">
        <CompanyNews title={companyNewsTitle} text={companyNewsText} />
      </div>
    </div>
  );
};

export default HomePage;
