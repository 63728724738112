import React, { useState, FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';

import arrowUpImg from '../../assets/images/arrowDownSmall.png';

interface FAQDropdownProps {
  question: string;
  answer: string;
  // isTroubleshooting?: boolean;
}

const FAQDropdown: FunctionComponent<FAQDropdownProps> = ({
  question,
  answer,
  // isTroubleshooting,
}) => {
  const [isArrowDown, setIsArrowDown] = useState(false);

  const onButtonClick = () => {
    setIsArrowDown(!isArrowDown);
  };

  let arrowClassName = isArrowDown ? 'arrow_img_rotated' : 'arrow_img';

  return (
    <div className="component__wrapper">
      <button onClick={onButtonClick}>
        <div className="question__wrapper">
          <p className="question__text">{question}</p>

          <img src={arrowUpImg} className={arrowClassName} alt="arrowRight" />
        </div>
      </button>

      <CSSTransition
        in={isArrowDown}
        transitionName="example"
        timeout={300}
        classNames="my-node"
        unmountOnExit
      >
        <p
          className="answer__text"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </CSSTransition>
    </div>
  );
};

export default FAQDropdown;
