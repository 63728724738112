import React, { useState } from 'react';

import { BlackButton } from '../..';

import { regexEmail, regexZipCode } from '../../../util/functions';

type inputType =
  | 'firstName'
  | 'lastName'
  | 'title'
  | 'company'
  | 'city'
  | 'state'
  | 'zip'
  | 'phone'
  | 'email';

const AgentForm = () => {
  const [inputData, setInputData] = useState({
    firstName: '',
    lastName: '',
    title: '',
    company: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
  });

  const handleInputData = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: inputType
  ) => {
    setInputData({
      ...inputData,
      [fieldName]: e.target.value,
    });
    console.log(inputData.firstName);
  };

  const isEmailValid = regexEmail.test(String(inputData.email));
  const isZipCodeValid = regexZipCode.test(String(inputData.zip));

  const isButtonDisabled =
    !isEmailValid ||
    !isZipCodeValid ||
    !inputData.firstName ||
    !inputData.lastName ||
    !inputData.title ||
    !inputData.company ||
    !inputData.city ||
    !inputData.state ||
    !inputData.phone;

  return (
    <form
      className="form__container"
      method="POST"
      action="https://forms.zohopublic.com/sigexllc/form/AgentApplication/formperma/gSsq1uNuLvA7-DYkb5UvNRiVX0nrii4sgdK6_uXSElM/htmlRecords/submit"
    >
      <label>First name</label>
      <input
        name="SingleLine"
        type="text"
        placeholder="John"
        value={inputData.firstName}
        onChange={(e) => handleInputData(e, 'firstName')}
      />
      <label>Last name</label>
      <input
        name="SingleLine1"
        type="text"
        placeholder="Malovitch"
        value={inputData.lastName}
        onChange={(e) => handleInputData(e, 'lastName')}
      />
      <label>Title</label>
      <input
        name="SingleLine2"
        type="text"
        placeholder="ex. System requirements"
        value={inputData.title}
        onChange={(e) => handleInputData(e, 'title')}
      />
      <label>Company</label>
      <input
        name="SingleLine3"
        type="text"
        placeholder="Your company"
        value={inputData.company}
        onChange={(e) => handleInputData(e, 'company')}
      />
      <label>City</label>
      <input
        name="SingleLine5"
        type="text"
        placeholder="ex. New York"
        value={inputData.city}
        onChange={(e) => handleInputData(e, 'city')}
      />
      <label>State</label>
      <input
        name="SingleLine6"
        type="text"
        placeholder="ex. New York"
        value={inputData.state}
        onChange={(e) => handleInputData(e, 'state')}
      />
      <label>ZIP</label>
      <input
        name="SingleLine7"
        type="text"
        placeholder="Zip code"
        value={inputData.zip}
        onChange={(e) => handleInputData(e, 'zip')}
      />
      <label>Phone</label>
      <input
        name="PhoneNumber_countrycode"
        type="text"
        placeholder="Phone number"
        value={inputData.phone}
        onChange={(e) => handleInputData(e, 'phone')}
      />
      <label>Email</label>
      <input
        name="Email"
        type="text"
        placeholder="name@mail.com"
        value={inputData.email}
        onChange={(e) => handleInputData(e, 'email')}
      />

      <BlackButton isDisabled={isButtonDisabled} text="Submit" type="submit" />
    </form>
  );
};

export default AgentForm;
