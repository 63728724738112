import React, { FunctionComponent } from 'react';

import image from '../../../../assets/images/secureAndSimple.png';
import mobileApp from '../../../../assets/images/mobileApp1.png';
import mobileApp2 from '../../../../assets/images/mobileApp2.png';
import webApp from '../../../../assets/images/webApp.png';
import concierge from '../../../../assets/images/concierge.png';

interface HowItWorksInterface {
  howItWorksText: string;
}

const HowItWorks: FunctionComponent<HowItWorksInterface> = ({
  howItWorksText,
}) => {
  return (
    <div className="howitworks__wrapper">
      <p className="howitworks__headline">How it works</p>
      <div className="howitworks__inner_wrapper">
        <div className="howitworks__text_wrapper">
          <p className="howitworks__text">{howItWorksText}</p>
        </div>
        <img src={image} alt="phoneImage" />
      </div>
      <p className="howitworks__semi_headline">
        An affordable, integrated service: Technology-driven, Human-supported
      </p>
      <div className="howitworks__images_wrapper">
        <div className="mobile_app__wrapper">
          <p className="image_headline">Mobile Application</p>
          <div className="mobile_app__inner_wrapper">
            <img
              src={mobileApp}
              alt="mobileApp_img"
              className="mobileApp_img1"
            />
            <img
              src={mobileApp2}
              alt="mobileApp2_img"
              className="mobileApp_img2"
            />
          </div>
        </div>
        <div className="web_app__wrapper">
          <p className="image_headline">Web Application</p>
          <div className="web_app__inner_wrapper">
            <img src={webApp} alt="webApp" className="webApp__img" />
          </div>
        </div>
        <div className="call_center_app__wrapper">
          <p className="image_headline">Call Center Application</p>
          <div className="call_center_app__inner_wrapper">
            <img src={concierge} alt="conciergeImg" className="concerge__img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
