import React, { useContext, useState } from 'react';

import { AwsContext } from '../../context';

import { FrequencySwitch, SubscriptionCard } from '../../components';

const SubscriptionPage = () => {
  const { subscriptions } = useContext(AwsContext);

  const [isMonthlyClicked, setIsMonthlyClicked] = useState(false);
  return (
    <div className="subscription_plans__wrapper">
      <p className="subscription_plans__headline">Subscription Plans</p>
      <p className="subscription_plans__text">
        725-contact offers three subscription levels to customers.
        <br />
        Plus and Premium subscriptions include entitlements to 24/7,
        <br />
        personalized phone support through 1-725-CONTACT, in case
        <br />
        of a lost, broken or stolen smartphone device.
      </p>
      <div className="subscriptions__wrapper">
        <FrequencySwitch
          isRightClicked={isMonthlyClicked}
          textLeft="Annually"
          textRight="Monthly"
          setIsRightClicked={setIsMonthlyClicked}
        />
        {subscriptions.length && (
          <div className="subscriptioncard__wrapper">
            {subscriptions.map((subscription, index) => (
              <SubscriptionCard
                key={index}
                subscriptionType={subscription}
                isMonthly={isMonthlyClicked}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPage;
