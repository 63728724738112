import React, { useContext, useEffect, useState } from 'react';
import { MarketingContext } from '../../context';

import image from '../../assets/images/hand-touching.jpg';

const AboutCompanyPage = () => {
  const { getAbout } = useContext(MarketingContext);

  const [info, setInfo] = useState(['']);

  useEffect(() => {
    getAbout().then((response) => {
      const splitIntoSentences = response.Info.replace(
        /([.?!])\s*(?=[A-Z])/g,
        '$1|'
      ).split('|');
      console.log(splitIntoSentences);
      setInfo(splitIntoSentences);
    });
  }, []);

  const splitIntoParagraphs = info.reduce(
    (accumulator, currentValue, index) => {
      if (index % 4 !== 0) {
        return accumulator + '  ' + currentValue;
      }
      return `${accumulator}\n${currentValue}`;
    },
    ''
  );

  return (
    <div className="about_company_page__wrapper">
      <p className="about_company__headline">The Company</p>
      <div className="content__wrapper">
        <p className="info__text">{splitIntoParagraphs}</p>

        <img
          src={image}
          alt="hand-touching-img"
          className="hand_touching__img"
        />
      </div>
    </div>
  );
};

export default AboutCompanyPage;
