import React, {
  createContext,
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from 'react';

import { AxiosContext } from './AxiosContext';

import { Subscription } from '../interfaces';

interface AwsContextProviderProps {
  children: React.ReactNode;
}

interface AwsContextState {
  subscriptions: Subscription[];
  getAllSubscriptions: () => {};
}

const AwsContext = createContext({} as AwsContextState);

const AwsContextProvider: FunctionComponent<AwsContextProviderProps> =
  ({ children }) => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>(
      [] as Subscription[]
    );

    const { axiosInstanceAws } = useContext(AxiosContext);

    const getAllSubscriptions = async () => {
      return axiosInstanceAws
        .get('/subscription/getAll')
        .then((response) => {
          console.log('SUPSKRIP{CIJE: ', response.data);
          setSubscriptions(response.data);
        })
        .catch((error) =>
          console.log('Error in getting subscriptions: ', error.response.data)
        );
    };

    useEffect(() => {
      getAllSubscriptions();
    }, []);

    const providerValue = {
      subscriptions,
      getAllSubscriptions,
    };

    return (
      <AwsContext.Provider value={providerValue}>
        {children}
      </AwsContext.Provider>
    );
  };

export { AwsContext, AwsContextProvider };
