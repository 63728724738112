import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  AppHeaderTextEnum,
  AboutDropDownTextEnum,
  PartnersDropDownTextEnum,
  ResourcesDropDownTextEnum,
  AboutDropDownLinkEnum,
  PartnersDropDownLinkEnum,
  ResourcesDropDownLinkEnum,
} from '../../util/enums';

import arrowImg from '../../assets/images/headerArrow.png';

interface HamburgerDropDownProps {
  headlineValue: AppHeaderTextEnum;
  setIsDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerDropDown: FunctionComponent<HamburgerDropDownProps> = ({
  headlineValue,
  setIsDropDownOpen,
}) => {
  const [isDropDownOpened, setDropDownOpened] = useState(false);

  const onHeadlineClick = () => {
    setDropDownOpened(!isDropDownOpened);
  };

  let dropDownContent: string[] = [];

  if (headlineValue === 'About') {
    dropDownContent = Object.keys(AboutDropDownTextEnum);
  }

  if (headlineValue === 'Partners') {
    dropDownContent = Object.keys(PartnersDropDownTextEnum);
  }

  if (headlineValue === 'Resources') {
    dropDownContent = Object.keys(ResourcesDropDownTextEnum);
  }

  const imgClassName = isDropDownOpened ? 'arrowImgRotated' : 'arrowImg';

  const closeDropDown = () => {
    setIsDropDownOpen(false);
  };

  return (
    <div className="hamburger_dropdown__wrapper">
      <div className="headlin_arrow_wrapper" onClick={onHeadlineClick}>
        <p>{headlineValue}</p>
        <img src={arrowImg} alt="arrowImg" className={imgClassName} />
      </div>

      {isDropDownOpened && (
        <div className="hamburger_drowpdown__items">
          {dropDownContent.length > 0 &&
            headlineValue === 'About' &&
            dropDownContent.map((key, i) => {
              const value =
                AboutDropDownTextEnum[
                  key as keyof typeof AboutDropDownTextEnum
                ];

              return (
                <Link
                  key={key}
                  className="dropdown_text"
                  onClick={closeDropDown}
                  to={
                    AboutDropDownLinkEnum[
                      key as keyof typeof AboutDropDownLinkEnum
                    ]
                  }
                >
                  {value}
                </Link>
              );
            })}
          {dropDownContent.length > 0 &&
            headlineValue === 'Resources' &&
            dropDownContent.map((key, i) => {
              const value =
                ResourcesDropDownTextEnum[
                  key as keyof typeof ResourcesDropDownTextEnum
                ];

              return (
                <Link
                  key={key}
                  className="dropdown_text"
                  onClick={closeDropDown}
                  to={
                    ResourcesDropDownLinkEnum[
                      key as keyof typeof ResourcesDropDownLinkEnum
                    ]
                  }
                >
                  {value}
                </Link>
              );
            })}
          {dropDownContent.length > 0 &&
            headlineValue === 'Partners' &&
            dropDownContent.map((key, i) => {
              const value =
                PartnersDropDownTextEnum[
                  key as keyof typeof PartnersDropDownTextEnum
                ];

              return (
                <Link
                  key={key}
                  className="dropdown_text"
                  onClick={closeDropDown}
                  to={
                    PartnersDropDownLinkEnum[
                      key as keyof typeof PartnersDropDownLinkEnum
                    ]
                  }
                >
                  {value}
                </Link>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default HamburgerDropDown;
