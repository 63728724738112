import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
} from 'react';

import { AxiosContext } from './index';

interface MarketingContextProviderProps {
  children: React.ReactNode;
}

interface MarketingContextState {
  getHomePage: () => Promise<any>;
  getFAQ: () => Promise<any>;
  getWhy725: () => Promise<any>;
  getAbout: () => Promise<any>;
  getContactUs: () => Promise<any>;
  getTroubleshooting: () => Promise<any>;
  getCareers: () => Promise<any>;
  getNewsPage: (page: string) => Promise<any>;
  getNewsPaginationCount: () => Promise<any>;
  getNewsItem: (id: string) => Promise<any>;
}

const MarketingContext = createContext<MarketingContextState>(
  {} as MarketingContextState
);

const MarketingContextProvider: FunctionComponent<MarketingContextProviderProps> =
  ({ children }) => {
    const { axiosInstance } = useContext(AxiosContext);

    const getHomePage = async () => {
      return axiosInstance
        .get('/home/index')
        .then((response) => {
          return response.data;
        })
        .catch((error) => console.log('HOME ERROR: ', error));
    };

    const getFAQ = async () => {
      return axiosInstance
        .get('/faq/index')
        .then((response) => {
          return response.data;
        })
        .catch((error) => console.log('FAQ ERROR:', error));
    };

    const getWhy725 = async () => {
      return axiosInstance
        .get('/why725/index')
        .then((response) => {
          return response.data;
        })
        .catch((error) => console.log('WHY725 ERROR:', error));
    };

    const getAbout = async () => {
      return axiosInstance
        .get('/about/index')
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('ABOUT ERROR:', error));
    };

    const getContactUs = async () => {
      return axiosInstance
        .get('/contactUs/index')
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('CONTACTUS ERROR:', error));
    };

    const getTroubleshooting = async () => {
      return axiosInstance
        .get('/troubleshooting/index')
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('INDEX ERROR:', error));
    };

    const getCareers = async () => {
      return axiosInstance
        .get('/careers/index')
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('CAREERS ERROR:', error));
    };

    const getNewsItem = async (id: string) => {
      return axiosInstance
        .get('/blog/get/'+id)
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('GET BLOG ITEM ERROR:', error));
    };

    const getNewsPage = async (page: string) => {
      return axiosInstance
        .get('/blog/getpage/'+page)
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('GET BLOG PAGE ERROR:', error));
    };

    const getNewsPaginationCount = async () => {
      return axiosInstance
        .get('/blog/getpagecount')
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log('GET BLOG PAGINATION COUNT ERROR:', error));
    };

    const providerValue = {
      getHomePage,
      getFAQ,
      getWhy725,
      getAbout,
      getContactUs,
      getTroubleshooting,
      getCareers,
      getNewsPage,
      getNewsPaginationCount,
      getNewsItem,
    };

    return (
      <MarketingContext.Provider value={providerValue}>
        {children}
      </MarketingContext.Provider>
    );
  };

export { MarketingContext, MarketingContextProvider };
