import React, { FunctionComponent } from 'react';

import { Subscription } from '../../../interfaces';

import SubscriptionText from '../SubscriptionText/SubscriptionText';

interface SubscriptionCardProps {
  subscriptionType: Subscription;
  isMonthly: boolean;
}

const SubscriptionCard: FunctionComponent<SubscriptionCardProps> = ({
  subscriptionType,
  isMonthly,
}) => {
  const price = isMonthly
    ? subscriptionType.monthlyPrice
    : subscriptionType.yearlyPrice;

  const priceFlag = isMonthly ? 'Month' : 'Year';

  return (
    <div className="subscription__authcard__wrapper">
      <div className={subscriptionType.name}>
        <div className="subscription__card__inner__wrapper">
          <p className="subscription__type__text">{subscriptionType.name}</p>
          <div className="subscription__price__wrapper">
            <p className="subscription__price__text">${price}</p>
            <p className="subscription__price__period">/ {priceFlag}</p>
          </div>
          <SubscriptionText subscriptionType={subscriptionType} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
