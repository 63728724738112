import React, { useState, useEffect, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { MarketingContext } from '../../context';


interface IItem {
  ID: number,
  Title: string,
  PhotoUrl: string,
  Text: string,
  Next: string,
  Previous: string,
}

const NewsItemPage = () => {
  const { getNewsItem } = useContext(MarketingContext);

  const { id } = useParams<{ id: string }>();
  
  const [item, setItem] = useState({} as IItem);

  useEffect(() => {
    getNewsItem(id).then((response) => {
      setItem(response);
    });
  }, []);

  return (
    <div className="single-news-item__wrapper">
      <div className="left-arrow__wrapper ">
        <a href={item.Previous}>
          <img src="/leftarrow.svg" className={"left-arrow__icon "+ (item.Previous === "" ? "hidden-arrow" : "")} alt="left arrow icon" />
        </a>        
      </div>
      <div className="content__wrapper">
        <p className="single-news-item__headline">{item.Title}</p>
        <img className="main__img" src={"https://dev.api.725-contact-marketing.marbleit.rs/"+item.PhotoUrl} alt="Main photo" />
        <div className="main__text" dangerouslySetInnerHTML={{ __html: item.Text }} />
      </div>
      <div className={"right-arrow__wrapper "+ (item.Next === "" ? "hidden-arrow" : "")}>
        <a href={item.Next}>
          <img src="/rightarrow.svg" className="right-arrow__icon" alt="right arrow icon" />
        </a>
      </div>
    </div>
  );
};

export default NewsItemPage;
