import React, { useState, useContext, useEffect } from 'react';

import { MarketingContext } from '../../context';

import { Career } from './components';

const CareersPage = () => {
  const { getCareers } = useContext(MarketingContext);

  const [careers, setCareers] = useState([
    {
      Description: '',
      ExperienceAndSkills: '',
      Position: '',
      Responsibilities: '',
    },
  ]);

  useEffect(() => {
    getCareers().then((response) => setCareers(response));
  }, []);

  return (
    <div className="careers_page__wrapper">
      <p className="careers__headline">Careers</p>
      {careers.map((career, index) => (
        <Career
          key={index}
          position={career.Position}
          responsabilities={career.Responsibilities}
          experienceAndSkills={career.ExperienceAndSkills}
          description={career.Description}
        />
      ))}
    </div>
  );
};

export default CareersPage;
