import React, { createContext, FunctionComponent } from 'react';
import axios, { AxiosInstance } from 'axios';

const MarketingBaseURL = 
  'https://dev.api.725-contact-marketing.marbleit.rs/api';

const url = 'https://1eocnxysy2.execute-api.us-west-2.amazonaws.com/api';

interface IAxiosContext {
  axiosInstance: AxiosInstance;
  axiosInstanceAws: AxiosInstance;
}

const AxiosContext = createContext({} as IAxiosContext);

interface AxiosContextProps {
  children: React.ReactNode;
}

const AxiosContextProvider: FunctionComponent<AxiosContextProps> = ({
  children,
}) => {
  const axiosInstance = axios.create({
    baseURL: MarketingBaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: () => true,
  });

  const axiosInstanceAws = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: () => true,
  });

  const providerValue = {
    axiosInstance,
    axiosInstanceAws,
  };

  return (
    <AxiosContext.Provider value={providerValue}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosContextProvider };
