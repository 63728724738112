import React, { useState, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import mailIcon from '../../../assets/images/mailIcon.png';
import facebook from '../../../assets/images/facebook.png';
import instagram from '../../../assets/images/instagram.png';
import twitter from '../../../assets/images/twitter.png';

import { regexEmail } from '../../../util/functions';

import {
  AppHeaderRoutesEnum,
  AppHeaderTextEnum,
  AppHeaderLinkEnum,
  AboutDropDownTextEnum,
  AboutDropDownLinkEnum,
  PartnersDropDownTextEnum,
  PartnersDropDownLinkEnum,
  ResourcesDropDownTextEnum,
  ResourcesDropDownLinkEnum,
} from '../../../util/enums';

interface AppFooterProps {
  // selectedItem: AppHeaderTextEnum;
  setSelectedItem: React.Dispatch<React.SetStateAction<AppHeaderTextEnum>>;
}

const AppFooter: FunctionComponent<AppFooterProps> = ({ setSelectedItem }) => {
  const [email, setEmail] = useState('');

  const isEmailValid = regexEmail.test(String(email));

  const renderAppHeaderMenuItems = () => {
    return Object.keys(AppHeaderTextEnum).map((key, index) => {
      const value = AppHeaderTextEnum[key as keyof typeof AppHeaderTextEnum];

      if (value !== 'About' && value !== 'Partners' && value !== 'Resources') {
        return (
          <Link
            key={index}
            to={AppHeaderLinkEnum[key as keyof typeof AppHeaderLinkEnum]}
            className="link_text"
            onClick={() => {
              setSelectedItem(value);
            }}
          >
            {value}
          </Link>
        );
      }
    });
  };

  const renderAboutHeaderMenuItems = () => {
    return Object.keys(AboutDropDownTextEnum).map((key, index) => {
      const value =
        AboutDropDownTextEnum[key as keyof typeof AboutDropDownTextEnum];

      return (
        <Link
          key={index}
          to={AboutDropDownLinkEnum[key as keyof typeof AboutDropDownLinkEnum]}
          className="link_text"
          onClick={() => {
            setSelectedItem(AppHeaderTextEnum.PARTNERS);
          }}
        >
          {value}
        </Link>
      );
    });
  };

  const renderResourcesHeaderMenuItems = () => {
    return Object.keys(ResourcesDropDownTextEnum).map((key, index) => {
      const value =
        ResourcesDropDownTextEnum[
          key as keyof typeof ResourcesDropDownTextEnum
        ];

      return (
        <Link
          key={index}
          to={
            ResourcesDropDownLinkEnum[
              key as keyof typeof ResourcesDropDownLinkEnum
            ]
          }
          className="link_text"
          onClick={() => {
            setSelectedItem(AppHeaderTextEnum.RESOURCES);
          }}
        >
          {value}
        </Link>
      );
    });
  };

  const renderPartnersHeaderMenuItems = () => {
    return Object.keys(PartnersDropDownTextEnum).map((key, index) => {
      const value =
        PartnersDropDownTextEnum[key as keyof typeof PartnersDropDownTextEnum];

      return (
        <Link
          key={index}
          to={
            PartnersDropDownLinkEnum[
              key as keyof typeof PartnersDropDownLinkEnum
            ]
          }
          className="link_text"
          onClick={() => {
            setSelectedItem(AppHeaderTextEnum.PARTNERS);
          }}
        >
          {value}
        </Link>
      );
    });
  };

  return (
    <div className="appfooter_wrapper">
      <div className="content_wrapper">
        <div className="newsletter_wrapper">
          <p className="logo_text">725-contact</p>
          <p className="newsletter_text">
            Join our newsletter to stay up to date on
            <br />
            features and releases
          </p>
          <form
            className="mail_form__container"
            method="POST"
            action="https://forms.zohopublic.com/sigexllc/form/Newsletter/formperma/a3J1sbgM7jzzGoncYzRc5S0bkgCjX10QB5vyGsZL25c/htmlRecords/submit"
          >
            <input
              className="email-input"
              name="Email"
              type="text"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <button type="submit" disabled={!isEmailValid}>
              <img src={mailIcon} alt="mailIcon" />
            </button>
          </form>
        </div>

        <div className="links_wrapper">
          {renderAppHeaderMenuItems()}
          {renderAboutHeaderMenuItems()}
          {renderResourcesHeaderMenuItems()}
          {renderPartnersHeaderMenuItems()}
        </div>
        <div className="social_wrapper">
          <a href="https://www.instagram.com/sigexllc/" target="_blank" rel="noreferrer">
            <img src={instagram} alt="instagram" className="instagram_img" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100072061662180" target="_blank" rel="noreferrer">
            <img src={facebook} alt="facebook" className="facebook_img" />
          </a>
          <a href="https://twitter.com/725Contact" target="_blank" rel="noreferrer">
            <img src={twitter} alt="twitter" className="twitter_img" />
          </a>
        </div>
      </div>

      <div className="rights_wrapper">
        <p className="right_text">© 2021 725-contact. All right reserved.</p>
        <div className="policy_terms_wrapper">
          <p><Link to={AppHeaderRoutesEnum.PRIVACYPOLICY}>Privacy Policy</Link></p>
          <p><Link to={AppHeaderRoutesEnum.TERMS}>Terms of Service</Link></p>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
