import React, { FunctionComponent } from 'react';

import { AxiosContextProvider } from './AxiosContext';
import { MarketingContextProvider } from './MarketingContext';
import { AwsContextProvider } from './AwsContext';
import { TwilioContextProvider } from './TwilioContext';

interface GlobalContextProps {
  children: React.ReactNode;
}

const GlobalContextProvider: FunctionComponent<GlobalContextProps> = ({
  children,
}) => {
  return (
    <AxiosContextProvider>
      <MarketingContextProvider>
        <AwsContextProvider>
          <TwilioContextProvider>
            {children}
          </TwilioContextProvider>
        </AwsContextProvider>
      </MarketingContextProvider>
    </AxiosContextProvider>
  );
};

export default GlobalContextProvider;
