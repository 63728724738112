import React from 'react';

import complianceImg from '../../../../assets/images/compliance.png';

const Compliance = () => {
  return (
    <div className="compliance__wrapper">
      <img src={complianceImg} alt="securityImage" className="compliance__img" />
      <div className="complience_text__wrapper">
        <p className="complience__headline">Compliance</p>
        <p className="complience__text">
          725-contact is architected in conformance with all major U.S. and
          global data privacy standards including GDPR, CCPA, and certification
          to ISO/IEC 2700. Data confidentiality, integrity and availability are
          the principles that underscore our patent-pending system design.
        </p>
        <p className="complience__text">
          725-contact data storage and communications transmission complies
          with:
        </p>
        <div className="list__wrapper">
          <li> Federal Information Management Security Act</li>
          <li> ISO 22301</li>
          <li> ISO/IEC 27001</li>
          <li> ISO/IEC 27002</li>
          <li> ISO/IEC 27031</li>
          <li> ISO/IEC 27032</li>
          <li> ISO/IEC 27701</li>
          <li> HIPAA</li>
          <li> GDPR</li>
          <li> CCPA</li>
        </div>
        <p className="complience__text">
          For further details, please consult our privacy policy and end-user
          license agreement.
        </p>
      </div>
    </div>
  );
};

export default Compliance;
