import React, { FunctionComponent } from 'react';

interface BlackButtonProps {
  type: 'button' | 'submit';
  isDisabled?: boolean;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const BlackButton: FunctionComponent<BlackButtonProps> = ({
  type,
  isDisabled,
  text,
  onClick,
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      className="black_button"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default BlackButton;
