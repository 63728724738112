import React, { FunctionComponent } from 'react';

import introducing from '../../../../assets/images/introducing.png';

interface GetStartedProps {
  title: string;
  text: string;
}

const GetStarted: FunctionComponent<GetStartedProps> = ({
  title,
  text,
}) => {
  return (
    <div className="getStarted__wrapper">
    <div className="getStarted__text_wrapper">
      <p className="headline__text">{title}</p>
      <div className="text__wrapper">
        <p
          className="getStarted__text"
          dangerouslySetInnerHTML={{ __html: text }}
        >
        </p>
        <p className="learnMore">
          Learn more: <a href="./why725" className="learnMore__link">Why subscribe to 725-contact?</a>
        </p>
      </div>
    </div>

    <img src={introducing} alt="getStartedImage" className="getStarted__img" />
    </div>
  );
};

export default GetStarted;
