import React, { useState, useEffect, useContext } from 'react';

import { MarketingContext } from '../../context';

import { BlackButton } from '../../components';

import { regexEmail } from '../../util/functions';

type inputType = 'email' | 'name' | 'topic' | 'message';

const ContactPage = () => {
  const { getContactUs } = useContext(MarketingContext);

  const [contactInfo, setContactInfo] = useState({
    AdditionalInfo: '',
    CorporateInfo: '',
    InquiriesInfo: '',
    InvestorInfo: '',
    Name: '',
    Topics: [''],
  });

  const [inputData, setInputData] = useState({
    email: '',
    name: '',
    topic: '',
    message: '',
  });

  const handleInputData = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: inputType
  ) => {
    setInputData({
      ...inputData,
      [fieldName]: e.target.value,
    });
  };

  const handleMessageInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputData({
      ...inputData,
      message: e.target.value,
    });
  };

  const isEmailValid = regexEmail.test(String(inputData.email));

  const isButtonDisabled =
    !isEmailValid || !inputData.name || !inputData.topic || !inputData.message;

  useEffect(() => {
    getContactUs().then((response) => setContactInfo(response));
  }, []);

  return (
    <div className="contact_page__wrapper">
      <p className="contact_page__headline">Contact Us</p>
      <div className="contact_page__inner_wrapper">
        <div className="form__wrapper">
          <form
            className="form__container"
            method="POST"
            action="https://forms.zohopublic.com/sigexllc/form/ContactUs/formperma/vvajamA2VuXI9RC6_td96YVvZ7Hm89jPBIODDFey-EU/htmlRecords/submit"
          >
            <label>Email</label>
            <input
              name="Email"
              type="text"
              placeholder="name@mail.com"
              value={inputData.email}
              onChange={(e) => handleInputData(e, 'email')}
            />
            <label>Your name</label>
            <input
              name="SingleLine"
              type="text"
              placeholder="John Malovitch"
              value={inputData.name}
              onChange={(e) => handleInputData(e, 'name')}
            />
            <label>Choose a topic</label>
            <input
              name="SingleLine1"
              type="text"
              placeholder="ex. System requirements"
              value={inputData.topic}
              onChange={(e) => handleInputData(e, 'topic')}
            />
            <label>Message</label>
            <textarea
              name="MultiLine"
              // type="text"
              rows={6}
              placeholder="Let us know"
              value={inputData.message}
              onChange={handleMessageInput}
            />

            <BlackButton
              isDisabled={isButtonDisabled}
              text="Send your feedback"
              type="submit"
            />
          </form>
        </div>
        <div className="contacts__wrapper">
          <p>{contactInfo.CorporateInfo}</p>
          <p>{contactInfo.InvestorInfo}</p>
          <p>{contactInfo.InquiriesInfo}</p>
          <p>
            <b>{contactInfo.AdditionalInfo}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
