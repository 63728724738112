import React, { FunctionComponent } from 'react';

import introducing from '../../../../assets/images/introducing.png';

interface CompanyNewsProps {
  title: string;
  text: string;
}

const CompanyNews: FunctionComponent<CompanyNewsProps> = ({
  title,
  text,
}) => {
  return (
    <div className="companyNews__wrapper">
      <img src={introducing} alt="companyNewsImage" className="companyNews__img" />
      <div className="companyNews__text_wrapper">
        <p className="headline__text">{title}</p>
        <div className="text__wrapper">
          <p
            className="companyNews__text"
            dangerouslySetInnerHTML={{ __html: text }}
          >
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyNews;
