import React from 'react';

import { AgentForm } from '../../components';

const AgentApplicationPage = () => {
  return (
    <div className="agent_application_page__wrapper">
      <p className="agent_application_page__headline">Agent application</p>
      <AgentForm />
    </div>
  );
};

export default AgentApplicationPage;
