import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import securityImg from '../../../../assets/images/security.jpg';
import { AppHeaderRoutesEnum } from '../../../../util/enums';

interface SecurityProps {
  securityText: string;
}

const Security: FunctionComponent<SecurityProps> = ({ securityText }) => {
  return (
    <div className="security__wrapper">
      <div className="security__text_wrapper">
        <p className="headline__text">Security</p>
        <div className="text__wrapper">
          <p
            className="security__text"
            dangerouslySetInnerHTML={{ __html: securityText }}
          />
        </div>
      </div>

      <img src={securityImg} alt="securityImage" className="security__img" />
    </div>
  );
};

export default Security;
