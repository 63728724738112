export enum AppHeaderTextEnum {
  HOME  = '',
  WHY725 = 'Why 725-contact',
  SUBSCRIPTION_PLAN = 'Subscription Plans',
  ABOUT = 'About',
  PARTNERS = 'Partners',
  RESOURCES = 'Resources',
  SIGNINSIGNUP = 'Sign In/ Sign Up',
  CONTACT = 'Contact',  
}

export enum AppHeaderLinkEnum {
  WHY725 = '/why725',
  SUBSCRIPTION_PLAN = '/subscription-plan',
  ABOUT = '/about',
  PARTNERS = '/partners',
  RESOURCES = '/resources',
  CONTACT = '/contact',
  SIGNINSIGNUP = '/signing',
}

export enum AppHeaderRoutesEnum {
  WHY725 = '/why725',
  SUBSCRIPTION_PLAN = '/subscription-plan',
  ABOUT = '/about',
  PARTNERS = '/partners',
  RESOURCES = '/resources',
  CONTACT = '/contact',
  SIGNINSIGNUP = '/signing',
  TERMS = '/terms-of-service',
  PRIVACYPOLICY = '/privacy-policy',
}

export enum AboutDropDownTextEnum {
  COMPANY = 'Company',
  NEWS = 'In the News',
  CAREERS = 'Careers',
}

export enum AboutDropDownLinkEnum {
  COMPANY = '/about/company',
  NEWS = '/about/news/1',
  CAREERS = '/about/careers',
}

export enum AboutDropDownRoutesEnum {
  COMPANY = '/about/company',
  NEWS = '/about/news/:id',
  NEWSITEM = '/about/newsitem/:id',
  CAREERS = '/about/careers',
}

export enum PartnersDropDownTextEnum {
  AGENTPROGRAM = 'Agent program',
  AGENTAPPLICATION = 'Agent application',
}

export enum PartnersDropDownLinkEnum {
  AGENTPROGRAM = '/partners/agent-program',
  AGENTAPPLICATION = '/partners/agent-application',
}

export enum PartnersDropDownRoutesEnum {
  AGENTPROGRAM = '/partners/agent-program',
  AGENTAPPLICATION = '/partners/agent-application',
}

export enum ResourcesDropDownTextEnum {
  FAQ = 'FAQs',
  TROUBLESHOOTING = 'Troubleshooting',
}

export enum ResourcesDropDownLinkEnum {
  FAQ = '/resources/faq',
  TROUBLESHOOTING = '/resources/troubleshooting',
}

export enum ResourcesDropDownRoutesEnum {
  FAQ = '/resources/faq',
  TROUBLESHOOTING = '/resources/troubleshooting',
}