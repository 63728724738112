import React from 'react';

import appleStore from '../../assets/images/appleStore.png';
import googleStore from '../../assets/images/googleStore.png';

const AppStore = () => {
  const onAppleStoreClick = () => {};
  const onGooglStoreClick = () => {};

  return (
    <div className="AppStore__wrapper">
      <p className="AppStore__headline">Get the App</p>
      <div className="store_images__wrapper">
        <button onClick={onAppleStoreClick}>
          <img src={appleStore} alt="appleStore" className="store__img" />
        </button>
        <button onClick={onGooglStoreClick}>
          <img src={googleStore} alt="googleStore" className="store__img" />
        </button>
      </div>
    </div>
  );
};

export default AppStore;
