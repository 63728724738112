import React, { FunctionComponent, useState } from 'react';

import { Link } from 'react-router-dom';
import { Squash as Hamburger, Squash } from 'hamburger-react';

import { HamburgerDropDown } from '../..';

import {
  AppHeaderTextEnum,
  AppHeaderLinkEnum,
  AboutDropDownTextEnum,
  AboutDropDownLinkEnum,
  PartnersDropDownTextEnum,
  PartnersDropDownLinkEnum,
  ResourcesDropDownTextEnum,
  ResourcesDropDownLinkEnum,
} from '../../../util/enums';

import arrowImg from '../../../assets/images/headerArrow.png';

interface AppHeaderProps {
  selectedItem: AppHeaderTextEnum;
  setSelectedItem: React.Dispatch<React.SetStateAction<AppHeaderTextEnum>>;
}

const AppHeader: FunctionComponent<AppHeaderProps> = ({
  selectedItem,
  setSelectedItem,
}) => {
  const [isOpenDropDownOpen, setIsDropDownOpen] = useState(false);

  const renderAppHeaderMenuItems = () => {
    return Object.keys(AppHeaderTextEnum).map((key, index) => {
      const headlineValue =
        AppHeaderTextEnum[key as keyof typeof AppHeaderTextEnum];

      let linkTextClassName = 'link_text';

      if (headlineValue === 'Contact') {
        linkTextClassName = 'link_contact';
      } else if (selectedItem === headlineValue) {
        linkTextClassName = 'link_text_underline';
      }

      let dropDownContent: string[] = [];

      if (headlineValue === 'About') {
        dropDownContent = Object.keys(AboutDropDownTextEnum);
      }

      if (headlineValue === 'Partners') {
        dropDownContent = Object.keys(PartnersDropDownTextEnum);
      }

      if (headlineValue === 'Resources') {
        dropDownContent = Object.keys(ResourcesDropDownTextEnum);
      }

      const isArrowVisible =
        headlineValue === 'About' ||
        headlineValue === 'Partners' ||
        headlineValue === 'Resources';

      return (
        <div className="dropdown">
          {!isArrowVisible && headlineValue !== 'Sign In/ Sign Up' && (
            <Link
              key={index}
              to={AppHeaderLinkEnum[key as keyof typeof AppHeaderLinkEnum]}
              onClick={() => setSelectedItem(headlineValue)}
              className={linkTextClassName}
            >
              {headlineValue}
            </Link>
          )}

          {!isArrowVisible && headlineValue === 'Sign In/ Sign Up' && (
            <a
              href="https://dev.725-contact-web.marbleit.rs/"
              target="_blank"
              rel="noreferrer"
              className={linkTextClassName}
            >
              {headlineValue}
            </a>
          )}

          {isArrowVisible && (
            <div className={linkTextClassName} key={index}>
              <p>{headlineValue}</p>
              <img src={arrowImg} alt="arrowImg" className="arrow_img" />
            </div>
          )}

          {dropDownContent.length > 0 && headlineValue === 'About' && (
            <div className="dropdown_content">
              <div className="separator" />
              <div className="dropdown__wrapper">
                {dropDownContent.map((key, i) => {
                  const value =
                    AboutDropDownTextEnum[
                      key as keyof typeof AboutDropDownTextEnum
                    ];

                  return (
                    <Link
                      key={key}
                      className="dropdown_text"
                      onClick={() => setSelectedItem(headlineValue)}
                      to={
                        AboutDropDownLinkEnum[
                          key as keyof typeof AboutDropDownLinkEnum
                        ]
                      }
                    >
                      {value}
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
          {dropDownContent.length > 0 && headlineValue === 'Partners' && (
            <div className="dropdown_content">
              <div className="separator" />
              <div className="dropdown__wrapper">
                {dropDownContent.map((key, i) => {
                  const value =
                    PartnersDropDownTextEnum[
                      key as keyof typeof PartnersDropDownTextEnum
                    ];

                  return (
                    <Link
                      className="dropdown_text"
                      key={key}
                      onClick={() => setSelectedItem(headlineValue)}
                      to={
                        PartnersDropDownLinkEnum[
                          key as keyof typeof PartnersDropDownLinkEnum
                        ]
                      }
                    >
                      {value}
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
          {dropDownContent.length > 0 && headlineValue === 'Resources' && (
            <div className="dropdown_content">
              <div className="separator" />
              <div className="dropdown__wrapper">
                {dropDownContent.map((key, i) => {
                  const value =
                    ResourcesDropDownTextEnum[
                      key as keyof typeof ResourcesDropDownTextEnum
                    ];

                  return (
                    <Link
                      key={key}
                      onClick={() => setSelectedItem(headlineValue)}
                      className="dropdown_text"
                      to={
                        ResourcesDropDownLinkEnum[
                          key as keyof typeof ResourcesDropDownLinkEnum
                        ]
                      }
                    >
                      {value}
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderHamburgerDropDownItems = () => {
    return Object.keys(AppHeaderTextEnum).map((key, index) => {
      const headlineValue =
        AppHeaderTextEnum[key as keyof typeof AppHeaderTextEnum];

      const handleLinkClick = () => {
        setSelectedItem(headlineValue);
        setIsDropDownOpen(false);
      };

      const isDropDown =
        headlineValue === 'About' ||
        headlineValue === 'Partners' ||
        headlineValue === 'Resources';

      return (
        <div className="hambeurger_link">
          {isDropDown && (
            <HamburgerDropDown
              headlineValue={headlineValue}
              setIsDropDownOpen={setIsDropDownOpen}
            />
          )}
          {!isDropDown && (
            <Link
              key={key}
              className="hamburger_link__text"
              onClick={handleLinkClick}
              to={AppHeaderLinkEnum[key as keyof typeof AppHeaderLinkEnum]}
            >
              {headlineValue}
            </Link>
          )}
        </div>
      );
    });
  };

  return (
    <div className="appheader__wrapper">
      <div className="appheader_inner_wrapper">
        <Link className="logo_text" to='/'>
          725-contact
        </Link>
        <div className="hamburger__wrapper">
          <Squash
            toggled={isOpenDropDownOpen}
            toggle={() => setIsDropDownOpen(!isOpenDropDownOpen)}
          />
        </div>

        <div className="links__wrapper">{renderAppHeaderMenuItems()}</div>
        {isOpenDropDownOpen && (
          <div className="dropdown_links__wrapper">
            {renderHamburgerDropDownItems()}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
