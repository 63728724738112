import React, { useState, useEffect, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { MarketingContext } from '../../context';

import { NewsItem } from './components';

interface IItem {
  ID: number,
  Title: string,
  PhotoUrl: string,
  Summary: string,
}

const NewsPage = () => {
  const { getNewsPage, getNewsPaginationCount } = useContext(MarketingContext);

  const { id } = useParams<{ id: string }>();
  
  const [items, setItems] = useState([] as IItem[])
  const [count, setCount] = useState(Number)

  const  renderItems = () => {
    return items.length > 0 && (
      items.map((item) => (
        <NewsItem key={item.ID} id={item.ID} photoUrl={item.PhotoUrl} title={item.Title} summary={item.Summary} />
      ))
    )
  }

  const renderPaginationItems = () =>  {
    let result: any[] = [];
    for (let i = 1; i <= count; i++) {
      result.push(<li key={i} className={i == parseInt(id) ? "active" : ""}><a href={"./"+i}>{i}</a></li>);
    }

    return result;
  }

  const  renderPagination = () => {
    return (count && 
      renderPaginationItems()
    )
  }

  useEffect(() => {
    getNewsPage(id).then((response) => {
      setItems(response);
    });

    getNewsPaginationCount().then((response) => {
      setCount(response)
    })
  }, []);

  return (
    <div className="news__wrapper">
      <div className="news__inner_wrapper">
        <div>
          <p className="news__headline">In the news</p>
        </div>
        <div className="news-items__wrapper">
          <div className="news-items">
            {renderItems()}
          </div>
        </div>
        <ul className="pagination">
          {renderPagination()}
        </ul>
      </div>
    </div>
  );
};

export default NewsPage;
