import React, { useState, FunctionComponent } from 'react';

import { AppHeader, AppFooter } from '../../components';

import { AppHeaderTextEnum } from '../../util/enums';

import headphoneImg from '../../assets/images/headphone.png';

interface AppTemplateProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}

const AppTemplate: FunctionComponent<AppTemplateProps> = ({ onClick, children }) => {
  const [selectedItem, setSelectedItem] = useState(AppHeaderTextEnum.HOME);

  return (
    <div className="template_wrapper">
      <AppHeader
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <div className="give_us_a_call__wrapper">
        <p className="call_text">
          Need help?
          <br />
          Give us a call
        </p>
        <button onClick={onClick}>
          <img
            src={headphoneImg}
            alt="headhoneImg"
            className="headphone__img"
          />
        </button>
      </div>
      <div className="content__wrapper">{children}</div>

      <AppFooter setSelectedItem={setSelectedItem} />
    </div>
  );
};

export default AppTemplate;
