import React, { FunctionComponent, useState } from 'react';

import { BlackButton } from '../../../../components';

import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

interface CareerProps {
  description: string;
  position: string;
  responsabilities: string;
  experienceAndSkills: string;
}

const Career: FunctionComponent<CareerProps> = ({
  description,
  position,
  responsabilities,
  experienceAndSkills,
}) => {
  const [isPlusClicked, setIsPlusClicked] = useState(false);

  const onButtonClick = () => {};

  return (
    <div>
      <div className="position__wrapper">
        <div className="label__wrapper">
          <p className="position">Position</p>
        </div>
        <div className="position_name__wrapper">
          <p className="position_name__text">{position}</p>
          <p
            className="add-delete__sign"
            onClick={() => setIsPlusClicked(!isPlusClicked)}
          >
            {isPlusClicked ? '-' : '+'}
          </p>
        </div>
      </div>

      {isPlusClicked && (
        <div className="dropdown__wrapper">
          <div className="section__wrapper">
            <div className="label__wrapper">
              <p className="label_name">Description</p>
            </div>
            <div className="text__wrapper">
              <p className="text">{description}</p>
            </div>
          </div>
          <div className="section__wrapper">
            <div className="label__wrapper">
              <p className="label_name">Responsabilities</p>
            </div>
            <div className="text__wrapper">
              <p className="text">{responsabilities}</p>
            </div>
          </div>
          <div className="section__wrapper">
            <div className="label__wrapper">
              <p className="label_name">Experience and Skills</p>
            </div>
            <div className="text__wrapper">
              <p className="text">{experienceAndSkills}</p>
            </div>
          </div>
          <div className="button__wrapper">
            <BlackButton text="Apply" type="button" onClick={onButtonClick} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Career;
