import React, { useEffect, useState, useContext } from 'react';

import termsAndConditions from '../../assets/privacyPolicy/privacyPolicy.json';

const PrivacyPolicy = () => {
  let privacyPolicy = Object.keys(termsAndConditions);

  return (
    <div className="privacy-policy__wrapper">
      <div className="content__wrapper">
        <h1 className="privacy-policy__headline">Privacy Policy</h1>
        <div className="privacy-policy__text">
          {privacyPolicy.length > 0 &&
            privacyPolicy.map((item, i) => (
            <span key={i}>
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
