import React, { useEffect, useState, useContext } from 'react';

import termsAndConditions from '../../assets/termsAndConditions/termsAndConditions.json';

const TermsPage = () => {
  const termsAndConditionsArray = Object.keys(termsAndConditions);

  return (
    <div className="terms__wrapper">
      <div className="content__wrapper">
        <h1 className="terms__headline">Terms of Service</h1>
        <div className="terms__text">
          {termsAndConditionsArray.length > 0 && 
            termsAndConditionsArray.map((item, i) => (
            <span key={i}>
              {item}
            </span>
          ))}
          </div>
      </div>
    </div>
  );
};

export default TermsPage;
