import { FunctionComponent, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { AxiosContext, TimerContext, TwilioContext } from "../../context";
import "./CallModal.scss";
import {Connection, Device} from "twilio-client";
import {contactPhone} from "../../config";
import Timer from "../../components/labels/Timer/Timer";
import hangup_icon from "../../assets/images/hangup_icon.svg";

interface CallModalProps{
    showModal : boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CallModal: FunctionComponent<CallModalProps> = (callModalProps) => {
    const { axiosInstanceAws } = useContext(AxiosContext);
    const [phoneReady, setPhoneReady] = useState(false);
    const [connected, setConnected] = useState(false);
    const [totalCallTime, setTotalCallTime] = useState(0);
    const [device, setDevice] = useState(new Device());
    const [connection, setConnection] = useState<Connection | undefined>(undefined);
    const [input, setInput] = useState('');
    // const {totalCallTime, resetTimer, startTimer,stopTimer} = useContext(TimerContext);
    const { token, saveTwilioToken } = useContext(TwilioContext); 
        
    const getToken = async () => {
        
        try{
            if(true)
            {
                const response =  await axiosInstanceAws.get("/callToken");
                if(!response)
                    console.log(`TwilioToken: ${response}`)
                else
                    saveTwilioToken(response.data);
            }
        }
        catch(err){
            console.log(`Error when trying to fetch the twilioToken: ${err}`);
        }
    }

    const call = () => {
      // device.connect({number: contactPhone}).customParameters.set("number", contactPhone);
      const connection = device.connect({number: contactPhone});

      connection.customParameters.set("number", contactPhone);

      setConnection(connection);
      setConnected(true);
      setTotalCallTime(0);
  }

    const handleSendDigit = (value: string) => {
      setInput(input + value);
      connection?.sendDigits(value);
  }

    const setupPhone = async () => {
        try{
        await getToken();
        if(token)
            device.setup(token)
        
        device.on("ready", handlerDevice => {
            call()
        })
        
        device.on("cancel", connection => {
            console.log("canceled");
        })
        device.on('disconnect', connection => {
            setPhoneReady(false);
            setConnected(false);
            setTotalCallTime(0);
        })

        
            
        }
        catch(error){
            console.log(`Error while trying to make a call\n${error}`);
    
        }
    };
    const clickHandler = () => {
        device.disconnectAll();
        callModalProps.setShowModal(!callModalProps.showModal)
        
    }

    useEffect(() => {
        if (connected) {
            let seconds = 0;
            
            const timerInterval = setInterval(
                () => {
                    seconds += 1;
                    setTotalCallTime(seconds);
                },
                1000
            )
    
            return () => {
                clearInterval(timerInterval);
            }
        }
    }, [connected])

    useEffect(() => {
        if(callModalProps.showModal && !device.isInitialized)
            setupPhone()
        else if(callModalProps.showModal && device.isInitialized)
        {
            console.log(device.connections)
            call();
        }
        
    }, [callModalProps.showModal]);

    
    return (
        <Modal
            isOpen={callModalProps.showModal}
            ariaHideApp={false}
            className="incoming_call__modal"
            overlayClassName="modal__overlay"
        >
            <div className="call__wrapper">
                {
                    connected
                    ? <>
                        <p className="call__in_call">In Call</p>
                        <Timer time={totalCallTime} />

                        <p>We are now connecting you to 725-contact.</p> 
				                <p>Please use the numeric keypad to respond to the voice prompts.</p>
                    </>
                    : <>
                        <p>Calling 725Contact</p>
                        <p className="call__dots">....</p>
                    </>
                }
                <div className="call__input_container">
                    <div className="call__input">
                        <p>{input}</p>
                    </div>
                </div>
                <div className="call__digits_container">
                    <div className="call__digits_row">
                        <div className="call__digit" onClick={() => handleSendDigit('1')}>
                            <span>1</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('2')}>
                            <span>2</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('3')}>
                            <span>3</span>
                        </div>
                    </div>
                    <div className="call__digits_row">
                        <div className="call__digit" onClick={() => handleSendDigit('4')}>
                            <span>4</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('5')}>
                            <span>5</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('6')}>
                            <span>6</span>
                        </div>
                    </div>
                    <div className="call__digits_row">
                        <div className="call__digit" onClick={() => handleSendDigit('7')}>
                            <span>7</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('8')}>
                            <span>8</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('9')}>
                            <span>9</span>
                        </div>
                    </div>
                    <div className="call__digits_row">
                        <div className="call__digit" onClick={() => handleSendDigit('*')}>
                            <span>*</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('0')}>
                            <span>0</span>
                        </div>
                        <div className="call__digit" onClick={() => handleSendDigit('#')}>
                            <span>#</span>
                        </div>
                    </div>
                </div>
                <img
                  src={hangup_icon}
                    alt=""
                    onClick={clickHandler}
                />
            </div>
        </Modal>
    );
};

export default CallModal;