import React, { useEffect, useState, useContext } from 'react';

import { MarketingContext } from '../../context';

import { AppStore, ResourcesDropDown } from '../../components';

const TroubleshootingPage = () => {
  const { getTroubleshooting } = useContext(MarketingContext);

  const [TroubleshootingContent, setTroubleshootingContent] = useState({
    Name: 'Troubleshooting',
    About: '',
    QuestionsAndAnswers: [
      {
        Question: '',
        Answer: '',
      },
    ],
  });

  useEffect(() => {
    getTroubleshooting().then((response) =>
      setTroubleshootingContent(response)
    );
  }, []);

  return (
    <div className="TroubleshootingPage__wrapper">
      <div className="TroubleshootingPage_inner__wrapper">
        <p className="TroubleshootingPage__headline">
          {TroubleshootingContent.Name}
        </p>

        {TroubleshootingContent.QuestionsAndAnswers.map((value) => (
          <ResourcesDropDown
            question={value.Question}
            answer={value.Answer}
            // isTroubleshooting
          />
        ))}
      </div>
      <div className="appstore_wrapper">
        <AppStore />
      </div>
    </div>
  );
};

export default TroubleshootingPage;
