import React, { useState, useEffect, useContext } from 'react';

import { MarketingContext } from '../../context';

import { Overview, HowItWorks, Security, Compliance } from './components';

const Why725Page = () => {
  const { getWhy725 } = useContext(MarketingContext);

  const [overviewText, setOverviewText] = useState<string[]>([] as string[]);
  const [howItWorksText, setHowItWorksText] = useState('');
  const [securityText, setSecurityText] = useState('');

  useEffect(() => {
    getWhy725().then((response) => {
      console.log(response);
      const responseParagraphs = response.Overview.split('•');
      const removedEmptyParagraphs = responseParagraphs.filter(
        (paragraph: any) => paragraph !== ''
      );

      setOverviewText(removedEmptyParagraphs);
      setHowItWorksText(response.HowItWorks);
      setSecurityText(response.Security);
    });
  }, []);

  return (
    <div className="why-275__wrapper">
      <div className="why-725__inner_wrapper">
        <Overview overviewText={overviewText} />
        <HowItWorks howItWorksText={howItWorksText} />
        <Security securityText={securityText} />
        <Compliance />
      </div>
    </div>
  );
};

export default Why725Page;
