import React, { FunctionComponent, useState } from 'react';

interface BlogItemProps {
  id: number;
  photoUrl: string;
  title: string;
  summary: string;
}

const BlogItem: FunctionComponent<BlogItemProps> = ({
  id,
  photoUrl,
  title,
  summary,
}) => {
  return (
    <div>
      <div className="news-item">
        <img className="news-image" src={"https://dev.api.725-contact-marketing.marbleit.rs/"+photoUrl} />
        <p className="news-item__headline">{title}</p>
        <p className="news-item__summary">{summary}</p>
        <p className="news-item__readmore"><a href={"/about/newsitem/"+id.toString()}>Read more &gt;</a></p>
      </div>
    </div>
  );
};

export default BlogItem;
