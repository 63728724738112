import React, { FunctionComponent } from 'react';
import './FrequencySwitch.scss';

interface FrequencySwitchProps {
  isRightClicked: boolean;
  textLeft: string;
  textRight: string;
  setIsRightClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const FrequencySwitch: FunctionComponent<FrequencySwitchProps> = ({
  isRightClicked,
  textLeft,
  textRight,
  setIsRightClicked,
}) => {
  const monthlyPickerClassName = isRightClicked
    ? 'clicked__picker'
    : 'not__clicked__picker';
  const annuallyPickerClassName = !isRightClicked
    ? 'clicked__picker'
    : 'not__clicked__picker';

  const monthlyTextPickerClassName = isRightClicked
    ? 'clicked__text'
    : 'not__clicked__text';
  const annuallyTextPickerClassName = !isRightClicked
    ? 'clicked__text'
    : 'not__clicked__text';

  const onMonthlyPickerClick = () => {
    if (!isRightClicked) setIsRightClicked(!isRightClicked);
  };

  const onAnnuallyPickerClick = () => {
    if (isRightClicked) setIsRightClicked(!isRightClicked);
  };

  return (
    <div className="frequency__picker__wrapper">
      <button
        className={annuallyPickerClassName}
        onClick={onAnnuallyPickerClick}
      >
        <p className={annuallyTextPickerClassName}>{textLeft}</p>
      </button>
      <div className="divider"></div>
      <button className={monthlyPickerClassName} onClick={onMonthlyPickerClick}>
        <p className={monthlyTextPickerClassName}>{textRight}</p>
      </button>
    </div>
  );
};

export default FrequencySwitch;
