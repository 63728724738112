import React, { useState, FunctionComponent } from 'react';

import arrowRight from '../../../../assets/images/arrowRight.png';
import arrowLeft from '../../../../assets/images/arrowLeft.png';
import mobilePhoneImg from '../../../../assets/images/mobilePage.png';

interface OverviewProps {
  overviewText: string[];
}

const Overview: FunctionComponent<OverviewProps> = ({ overviewText }) => {
  const [paragraphIndex, setParagraphIndex] = useState(0);

  const ref = React.createRef<HTMLDivElement>();

  const onClick = (direction: 'left' | 'right') => {
    const windowWidht = window.screen.width;

    let scrollOffset = (40 * windowWidht) / 100;

    if (direction === 'left') {
      scrollOffset = -scrollOffset;
    }

    if (ref && ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
    if (scrollOffset > 0 && paragraphIndex < overviewText.length - 1) {
      setParagraphIndex(paragraphIndex + 1);
    } else if (scrollOffset < 0 && paragraphIndex > 0)
      setParagraphIndex(paragraphIndex - 1);
  };

  return (
    <div className="overview">
      <p className="why-275__headline">Why 725-contact?</p>
      <div className="overview__weapper">
        <img
          src={mobilePhoneImg}
          alt="mobilePhoneImg"
          className="overview_mobilePhone__img"
        />
        <button onClick={() => onClick('left')} className="arrow_btn">
          <img src={arrowLeft} alt="leftArrow" className="arrow_img" />
        </button>
        <div className="overview_texts_dots__wrapper">
          <div className="overview_texts__wrapper" ref={ref}>
            {overviewText.length > 0 &&
              overviewText.map((paragraph, index) => {
                return (
                  <div className="overview_text__wrapper" key={index}>
                    <p className="overview__text">{paragraph}</p>
                  </div>
                );
              })}
          </div>
          <div className="dots__wrapper">
            {overviewText.length > 0 &&
              overviewText.map((paragraph, index) => {
                const dotClassName =
                  index === paragraphIndex ? 'dot_black' : 'dot_grey';
                return <div key={index} className={dotClassName} />;
              })}
          </div>
        </div>
        {overviewText.length > 0}
        <button onClick={() => onClick('right')} className="arrow_btn">
          <img src={arrowRight} alt="rightArrow" className="arrow_img" />
        </button>
      </div>
    </div>
  );
};

export default Overview;
