import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AppTemplate } from './templates';

import {
  HomePage,
  Why725Page,
  ContactPage,
  CareersPage,
  SubscriptionPage,
  FAQPage,
  TroubleshootingPage,
  SalesAgentProgramPage,
  AgentApplicationPage,
  AboutCompanyPage,
  NewsPage,
  NewsItemPage,
  TermsPage,
  PrivacyPolicyPage,
} from './pages';

import {
  AboutDropDownRoutesEnum,
  AppHeaderRoutesEnum,
  PartnersDropDownRoutesEnum,
  ResourcesDropDownRoutesEnum,
} from './util/enums';

import './App.css';
import './scss/main.scss';
import CallModal from './pages/CallModal/CallModal';

function App() {
  const [showCallingModal, setShowCallingModal] = useState(false);

  return (
    <div className="App">
      <Switch>
        <AppTemplate onClick={() => setShowCallingModal(true)}>
          <Route exact path='/'>
            <HomePage />
          </Route>

          <Route path={AppHeaderRoutesEnum.WHY725}>
            <Why725Page />
          </Route>

          <Route path={AppHeaderRoutesEnum.SUBSCRIPTION_PLAN}>
            <SubscriptionPage />
          </Route>

          <Route path={AboutDropDownRoutesEnum.COMPANY}>
            <AboutCompanyPage />
          </Route>
          <Route path={AboutDropDownRoutesEnum.NEWS}>
            <NewsPage />
          </Route>
          <Route path={AboutDropDownRoutesEnum.NEWSITEM}>
            <NewsItemPage />
          </Route>
          <Route path={AboutDropDownRoutesEnum.CAREERS}>
            <CareersPage />
          </Route>

          <Route path={ResourcesDropDownRoutesEnum.FAQ}>
            <FAQPage />
          </Route>
          <Route path={ResourcesDropDownRoutesEnum.TROUBLESHOOTING}>
            <TroubleshootingPage />
          </Route>

          <Route path={AppHeaderRoutesEnum.CONTACT}>
            <ContactPage />
          </Route>

          <Route path={PartnersDropDownRoutesEnum.AGENTPROGRAM}>
            <SalesAgentProgramPage />
          </Route>
          <Route path={PartnersDropDownRoutesEnum.AGENTAPPLICATION}>
            <AgentApplicationPage />
          </Route>

          <Route path={AppHeaderRoutesEnum.TERMS}>
            <TermsPage />
          </Route>

          <Route path={AppHeaderRoutesEnum.PRIVACYPOLICY}>
            <PrivacyPolicyPage />
          </Route>

          <CallModal showModal={showCallingModal} setShowModal={setShowCallingModal}  />
        </AppTemplate>
      </Switch>
    </div>
  );
}

export default App;
