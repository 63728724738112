import React, { FunctionComponent } from 'react';

import hero from '../../../../assets/images/hero.jpg';

const Hero = () => {
  return (
    <div className="hero__wrapper">
      <img className="hero" src={hero} />
    </div>
  );
};

export default Hero;
